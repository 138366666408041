import React, { useEffect, useState, useContext, useRef } from 'react';
import CardContainerTwoC from '../container/CardContainerTwoC';
import CardButton from './CardButton';
import GlassesContainer from '../container/GlassesContainer';
import ModelViewerComponent from './ModelViewerComponent';
import styles from './SingleGlassesPage.module.css'
import { CatalogDataContext } from '../../data/CatalogueDataContext';
import IMAGES from '../../data/images';
import MirrorTittle from './MirrorHeader';
import CircularButton from './CircularButton';
import gsap from 'gsap';
import LOTIE from '../../data/lottie';
import Lottie from 'react-lottie';
import { useTranslation } from 'react-i18next';

function SingleGlassesPage({ randomGlasses, selectedGlasses, translate, openVto, handleOpenBrandPage }) {

    const { t } = useTranslation();

    const [showDado, setShowDado] = useState(false);
    const [shouldAnimate, setShouldAnimate] = useState(true);

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: LOTIE.lottieJsonDad,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };

    const modelViewerRef = useRef(null);
    const variantMenuContainerRef = useRef([]);
    const nameGlassesRef = useRef(null);
    const buttonGlassesRef = useRef(null);

    const colorLineRef = useRef(null);
    const mirrorTitleRef = useRef(null);
    const buttonRefs = useRef([useRef(), useRef(), useRef()]); // Un ref per ogni CircularButton

    const [listOfVariant, setListOfVariant] = useState([])

    const {
        selectedVariant,
        saveSelectedVariant,
        filteredGlassesList,
        saveSelectedGlasses,
    } = useContext(CatalogDataContext);

    useEffect(() => {
        //Dovrei prendere la size 0 non fare il map
        selectedGlasses.listaTaglieOBJ.map((size) => {
            // console.log("Size For Glasses", size);

            saveSelectedVariant(size.listaModelli[0]);
            setListOfVariant(size.listaModelli)
        })
      
        if(shouldAnimate){
          // Animazioni per gli elementi della lista listOfVariant
          if (variantMenuContainerRef.current) {
            gsap.fromTo(
              variantMenuContainerRef.current,
              { x: 600, rotationY: -90, z: 500 },
              { x: 0, rotationY: 0, z: 0, duration: 1, ease: "power3.out" }
            );
          }

          buttonRefs.current.forEach((ref, index) => {
            gsap.fromTo(
              ref.current,
              { y: -400 }, // Inizia da 100 pixel sopra la posizione iniziale
              { y: 0, duration: 0.7, delay: 0.2 * index, ease: "power3.out" } // "Cade" nella posizione finale
            );
          });

          gsap.fromTo(
            mirrorTitleRef.current,
           { x: 600, rotationY: -90, z: 500 },
          { x: 0, rotationY: 0, z: 0, duration: 1, ease: "power3.out" }
          );
        
          if(nameGlassesRef){
            gsap.fromTo(
                nameGlassesRef.current,
                { x: 600, rotationY: -90, z: 500 },
                { x: 0, rotationY: 0, z: 0, duration: 1, ease: "power3.out" }
              );
          }

          if(buttonGlassesRef){
            gsap.fromTo(
                buttonGlassesRef.current,
                { x: 600, rotationY: -90, z: 500 },
                { x: 0, rotationY: 0, z: 0, duration: 1, ease: "power3.out" }
              );
          }

          if(colorLineRef){
            gsap.fromTo(
              colorLineRef.current,
              { x: -800, rotateZ: 90 }, 
              { x: 0, rotateZ: 0, duration: 1.3, ease: "power3.out" } 
            );
            }
        }
    }, [selectedGlasses])

    function handleChangeVariant(variant) {
        saveSelectedVariant(variant);
    }

    const handleRandomGlasses = () => {
        setShouldAnimate(false);
        // Chiamata a randomGlasses
        if (filteredGlassesList && filteredGlassesList.length > 0) {
            // Seleziona un indice casuale dall'array
            let randomGlasses;

    // Cicla finché non trovi un occhiale diverso da quello attuale
    do {
      // Seleziona un indice casuale dall'array
      const randomIndex = Math.floor(Math.random() * filteredGlassesList.length);
      // Ottieni l'oggetto glasses corrispondente all'indice casuale
      randomGlasses = filteredGlassesList[randomIndex];
    } while (randomGlasses === selectedGlasses); // Ripeti se è lo stesso di quello attuale

            // Esegui la funzione saveSelectedGlasses con l'oggetto glasses selezionato
            saveSelectedGlasses(randomGlasses);
    
            // console.log("Selected Random Glasses:", randomGlasses);
        } else {
            console.log("La lista degli occhiali è vuota o non definita");
        }    
        // Mostra il lottie del dado
        setShowDado(true);
    
       
    };

    function handleShowDado(){
         // Nascondi il lottie del dado dopo 1 secondo
            setShowDado(false);
    }
    


    // console.log("SEL", selectedGlasses);
    return (
        <>

            <GlassesContainer >
            <MirrorTittle title={t("your_perfect_styles")} subtitle={t("move_the_glasses_for_a_better_look")} skip={false} ref={mirrorTitleRef}/>

                <div className={styles.line} >
                    <img className={styles.line_image} src={IMAGES.line} ref={colorLineRef}/>
                </div>
                <div className={styles.buttons_container}>

                <CircularButton variation={1} handleClick={handleRandomGlasses} ref={buttonRefs.current[0]} />
                <CircularButton variation={2} handleClick={openVto} ref={buttonRefs.current[1]} />
                <CircularButton variation={3} handleClick={handleOpenBrandPage} ref={buttonRefs.current[2]} />
                </div>
                {showDado && (
 <div className={styles.lottie_animation}>
 <Lottie options={defaultOptions} height={'40vh'} width={400} />
</div>
                )}
           

            <ModelViewerComponent handleShowDado={handleShowDado} dado={showDado} glassesVariant={selectedVariant} ref={modelViewerRef}></ModelViewerComponent>
               
                <div className={styles.chooseColor} > {t("choose_your_color")}</div>
                <div className={styles.variantMenuContainer} ref={variantMenuContainerRef}>

                    {listOfVariant.map((variant, index) => {

                        return (
                            <>
                                {/* <button key={index} >{variant.coloreFrame}</button> */}
                                <button key={index} onClick={() => handleChangeVariant(variant)} className={styles.box}>
                                    <div key={index} className={styles.rectangle} style={{
                                        backgroundImage: `linear-gradient(190deg, ${variant.esaColorFramePrimary} 0%, 
                                            ${variant.esaColorLensesPrimary} 0.5%, 
                                            ${variant.esaColorFrameSecondary} 0.01%, 
                                            ${variant.esaColorLensesSecondary} 100%)`
                                    }} />
                                </button>
                            </>

                        );
                    })}
                </div>

                <div className={styles.glassName} ref={nameGlassesRef}>
                    {selectedGlasses?.nome_modello}
                </div>
                {/* <div className={styles.brandcontainerLogo}>
                    <img src={selectedGlasses.imgUrl} className={styles.brandimage} />

                </div> */}
                <button className={styles.button} onClick={openVto} ref={buttonGlassesRef}>
                    {t("try_it_on")}
                </button>

            </GlassesContainer>

        </>
    );
}

export default SingleGlassesPage;