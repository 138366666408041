import React, { Children, useEffect, useState,useRef } from 'react';
import CardContainerTwoCFull from '../container/CardContainerTwoCFull';
import CardButton from './CardButton';
import MirrorGlassesCard from './MirrorGlassesCard';
import BrandHeader from './BrandHeader';
import MirrorTittle from './MirrorHeader';
import styles from './SotreLocatore.module.css'
import { useTranslation } from 'react-i18next';
import gsap from "gsap";
import CardContainerHorizontal from '../container/CardContainerHorizontal';
import MirrorGlassesCardHorizontal from './MirrorGlassesCardHorizontal';
import FlexContainer from '../container/FlexContainerVertical';
import CardContainerBig from './CardFullWidth';
import SearchBar from './SearchBar';

function StoreLocatorPage({ selectedBrand, translate ,listOfGlasses, handleCardClick}) {
    const { t } = useTranslation();
    const mirrorTitleRef = useRef(null);


    useEffect(()=> {

        gsap.fromTo(
            mirrorTitleRef.current,
           { x: 600, rotationY: -90, z: 500 },
          { x: 0, rotationY: 0, z: 0, duration: 1, ease: "power3.out" }
          );
    },[])
  

    return (
        <>
        <div className={styles['store-locator-index']}>
        <div style={{ marginTop: "5%" , perspective: "1874px"}}>
      <MirrorTittle
        ref={mirrorTitleRef}
        title={"Find Stores Near you"}
        subtitle={false}
        skip = {false}
      />
      <CardContainerTwoCFull margin={10}>
        
      <SearchBar onSearch={''}></SearchBar>
     
      <CardContainerBig></CardContainerBig>
      </CardContainerTwoCFull>
    </div>
        </div>

        </>
    );
}

export default StoreLocatorPage;