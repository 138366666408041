// constants.js

//ARSHADES
import logo from "../asset/logo-arshades.svg";
import logoPermision from "../asset/arshades_logo_take_pic.png";

//SAFILO
// import logo from "../asset/Safilo-logo.png";
// import logoPermision from "../asset/Safilo-logo.png";

//BOSS EyeWear
// import logo from "../asset/BossLogo.png";
// import logoPermision from "../asset/BossLogo.png";
// import coverImg from "../asset/coverBoss.jpg"

export const whitelist = ["http://localhost:3000/","https://www.webvto.it/","https://webvto.it/", "https://www.safilo.webvto.it/","https://safilo.webvto.it/", "https://www.arshades.it/"];

// //Arshades
export const IOS_APP_STORE_URL = 'https://apps.apple.com/us/app/arshades-demo/id1586661818';
export const ANDROID_APP_STORE_URL = 'https://play.google.com/store/apps/details?id=it.spaarkly.arshades&hl=en_US&gl=US';
export const MESSAGE_TITLE = "ARShades";
export const MESSAGE_SUBTITLE = "Open in the ASHades App";
export const WATERMARK = "https://firebasestorage.googleapis.com/v0/b/arshades-7e18a.appspot.com/o/immagini%2Fbrand_logo%2Farshades_logo_scritta.png?alt=media&token=9da35105-68a9-45e1-a321-98411589d34f";
export const PRIMARY_COLOR = "#42B1AC";
export const LOGOPERM = logoPermision;
export const LOGO = logo;
export const WEBSITE = "https://www.webvto.it/"
//  export const WEBSITE = "http://localhost:3000/"
export const WEBSITEIOS ="https://webvto.it/"
export const PRIVACY_POLICY_DOC = "jKYBjtE5h60UcA2xVD8O"
export const TERMS_AND_CONDITION_DOC = "uLTrBxjCAWbaaVBh9jdy"
export const COVER_TEMPLATE = "DEFAULT"


export const AXIOS_HEADERS = {
    'Content-Type': 'application/json',
    'ssk': 'k4imz-c413c26805',
  };
export const EMAIL_FROM = "spaarklyvto@gmail.com"
export const EMAIL_URL = "https://us-central1-arshades-7e18a.cloudfunctions.net/sendEmail";
// export const URL_COVER_IMG = ""


// //Safilo
// export const IOS_APP_STORE_URL = 'https://apps.apple.com/us/app/safilo-vto/id6446645854';
// export const ANDROID_APP_STORE_URL = 'https://play.google.com/store/apps/details?id=it.spaarkly.safilo';
// export const MESSAGE_TITLE = "Safilo VTO";
// export const MESSAGE_SUBTITLE = "Open in the Safilo VTO App";
// export const WATERMARK = logo;
// export const PRIMARY_COLOR = "#86ACCA"
// export const LOGOPERM = logoPermision;
// export const LOGO = logo;
// export const WEBSITE = "https://www.mirror.webvto.it/"
// // export const WEBSITE = "http://localhost:3000/"

// export const WEBSITEIOS ="https://safilo.webvto.it/"
// export const PRIVACY_POLICY_DOC = "apVjQIUjGKR2BdDmlihh"
// export const TERMS_AND_CONDITION_DOC = "uhOAuuHlosGyYbzdjXDW"
// export const COVER_TEMPLATE = "DEFAULT"
// export const URL_COVER_IMG = ""


// //BOSS
// export const IOS_APP_STORE_URL = '';
// export const ANDROID_APP_STORE_URL = '';
// export const MESSAGE_TITLE = "Boss EyeWear VTO";
// export const MESSAGE_SUBTITLE = "Open in the Boss EyeWear VTO";
// export const WATERMARK = logo;
// export const PRIMARY_COLOR = "#987147"
// export const LOGOPERM = logoPermision;
// export const LOGO = logo;
// export const WEBSITE = "https://www.bosseyewear.webvto.it/"
// export const WEBSITEIOS ="https://bosseyewear.webvto.it/"
// export const PRIVACY_POLICY_DOC = "apVjQIUjGKR2BdDmlihh"
// export const TERMS_AND_CONDITION_DOC = "uhOAuuHlosGyYbzdjXDW"
// export const COVER_TEMPLATE = "IMAGE_COVER"
// export const URL_COVER_IMG = coverImg