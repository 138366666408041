import React, { createContext, useState, useEffect } from "react";
import { db } from "../data/base"; // Assicurati che questo import sia corretto
import { doc, getDoc } from "@firebase/firestore";
import { occhialeConverter } from "../modello/Occhiale";
import { tagliaConverter } from "../modello/Taglia";
import { modelloConverter } from "../modello/Modello";
import { brandConverter } from "../modello/Brand";
import { lineaConverter } from "../modello/Linea";
import { tagConverter } from "../modello/Tag";
import {
  mockBrandData1,
  mockBrandData2,
  mockBrandData3,
  mockBrandData4,

  BrandContent,
} from "../modello/BrandContent";
import {
  mockGlassesData1,
  mockGlassesData2,
  mockGlassesData3,
  GlassesContent,
} from "../modello/GlassesContent";
import {
  saveData,
  loadData,
  saveSelectedVariantDB,
  saveSelectedBrandDB,
  saveSelectedGlassesDB,
  clearData,
  saveIsSessionCooming,
  loadPageStates,
  savePageStates,
  loadSelectionStates,
  saveSelectionStates,
  saveFilteredListDB,
} from "../data/IndexedDb";

export const CatalogDataContext = createContext();

export const CatalogDataProvider = ({ children, c }) => {

  const [catalogs, setCatalogs] = useState([]);
  const [listOfGlasses, setListOfGlasses] = useState([]);
  const [loadingProgress, setLoadingProgress] = useState(0); // Nuova variabile di stato per il progresso
  const [listOfTags, setListOfTags] = useState([]);
  const [selectedGlasses, setSelectedGlasses] = useState();
  const [selectedVariant, setSelectedVariant] = useState();
  const [selectedBrand, setSelectedBrand] = useState();

  //Glasses List
  const [filteredGlassesList, setFilteredGlassesList] = useState([]);
  const [isNewSession, setIsNewSession] = useState(false);

  //SESSION STATE

  //SELECTION
  const [selectedGender, setSelectedGender] = useState(false);
  const [selectedShape, setSelectedShape] = useState(false);
  const [selectedType, setSelectedType] = useState(false);
  //PAGE STATE VISIBILITY
  const [isBrandPage, setIsBrandPage] = useState(false);
  const [isSingleBrandPage, setIsSingleBrandPage] = useState(false);
  const [isSingleGlassesPage, setIsSingleGlassesPage] = useState(false);
  const [startSelectionLanguage, setStartSelectionLanguage] = useState(false);
  const [isFilteredByShape, setIsFiltereByShape] = useState(false);
  const [isFilteredByType, setIsFiltereByType] = useState(false);

  const [isfilteredByGender, setIsFilteredByGender] = useState(false);

  const [allShapes, setAllShapes] = useState([]);
  const [allType, setAllType] = useState([]);

  function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        // Genera un indice casuale
        const j = Math.floor(Math.random() * (i + 1));
        // Scambia l'elemento corrente con l'elemento all'indice casuale
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
}

  const getGlassesByBrand = (brandId) => {
    if (!brandId || !Array.isArray(listOfGlasses)) {
      return [];
    }

    const filteredGlasses = listOfGlasses.filter(
      (glasses) => glasses.brandId === brandId
    );

    return filteredGlasses;
  };

  const getGlassesByBrandFiltered = (brandId) => {
    if (!brandId || !Array.isArray(filteredGlassesList)) {
      return [];
    }
    // console.log("LISTA FILTRATA DI BRAND",filteredGlassesList);
    const filteredGlasses = filteredGlassesList.filter(
      (glasses) => glasses.brandId === brandId
    );

    return filteredGlasses;
  };

  const getAllFormCategories = (glassesList, tag) => {
    const allForms = glassesList.flatMap((glasses) =>
      glasses.listaCategorieObj.filter((category) => category.group === tag)
    );
    const uniqueForms = Array.from(new Set(allForms.map((form) => form.name)));
    return uniqueForms;
  };

  const filterGlassesByForm = (formName, glassesList, group) => {
    let filteredGlasses = [];
    if (glassesList) {
      filteredGlasses = glassesList.filter((glasses) =>
        glasses.listaCategorieObj.some(
          (category) => category.group === group && category.name === formName
        )
      );
    } else {
      filteredGlasses = listOfGlasses.filter((glasses) =>
        glasses.listaCategorieObj.some(
          (category) => category.group === group && category.name === formName
        )
      );
    }

    setFilteredGlassesList(filteredGlasses);
    if(group === "Forma"){
      setIsFiltereByShape(true);
    }else{
      setIsFiltereByType(true);
    }

    return filteredGlasses;
  };

  const filterGlassesByGender = (filter) => {
    let listOfGlassesFiltered = [];
    // console.log("LIST OF GLASSES",listOfGlasses )
    if (filter === "Kids") {
      listOfGlassesFiltered = listOfGlasses.filter(
        (glasses) =>
          glasses.lineaObj &&
          (glasses.lineaObj.nome_linea.includes("KIDS") ||
            glasses.lineaObj.nome_linea.includes("TEEN"))
      );
    } else {
      listOfGlassesFiltered = listOfGlasses.filter((glasses) =>
        glasses.listaCategorieObj.some((category) => category.name === filter)
      );
    }

    setIsFilteredByGender(true);
    setFilteredGlassesList(listOfGlassesFiltered);
    return listOfGlassesFiltered;
  };

  const filterGlasses = (genderFilter, typeFilter, formFilter ,glassesList) => {
    let filteredGlasses = glassesList || listOfGlasses; // Usa glassesList se fornita, altrimenti usa listOfGlasses
    // console.log("LIST in CATALOGUE", filteredGlasses);
    if (genderFilter) {
      filteredGlasses = filterGlassesByGender(genderFilter);
      // console.log("LIST in CATALOGUE GENDER", filteredGlasses, genderFilter);

    }
    if (typeFilter){
      filteredGlasses = filterGlassesByForm(typeFilter,filteredGlasses, "Tipologia");
    }
    if (formFilter) {
      filteredGlasses = filterGlassesByForm(formFilter, filteredGlasses, "Forma");
      // console.log("LIST in CATALOGUE FORM", filteredGlasses,formFilter);

    }
  
    setFilteredGlassesList(filteredGlasses);
    return filteredGlasses;
  };
  

  const getCatalogById = (catalogId) => {
    return catalogs.find((catalog) => catalog.id === catalogId);
  };
  // Qui includi tutte le funzioni di caricamento dei dati come fetchCatalogsFromDB, loadMockGlassesContent, etc.

  const getAllVariant = () => {
    const allVariant = [];
    listOfGlasses.map((glasses) => {
      glasses.listaTaglieOBJ.map((size) => {
        size.listaModelli.map((variant) => {
          // console.log("variant",variant)
          allVariant.push(variant);
        });
      });
    });
    return allVariant;
  };

  async function saveSelectedVariant(selectedVariant) {
    setSelectedVariant(selectedVariant);
    await saveSelectedVariantDB(selectedVariant);
  }

  async function saveSelectedGlasses(selectedGlasses) {
    setSelectedGlasses(selectedGlasses);
    await saveSelectedGlassesDB(selectedGlasses);
  }

  async function saveSelectedBrand(selectedBrand) {
    setSelectedBrand(selectedBrand);
    await saveSelectedBrandDB(selectedBrand);
  }

  async function clearSession() {
    await clearData();
  }

  async function setStartNewSession(value) {
    setIsNewSession(value);
    await saveIsSessionCooming(value);
  }

  async function saveSelectedShape(selectedShape) {
    setSelectedShape(selectedShape);
    await saveSelectionStates(selectedGender, selectedShape, selectedType);
  }

  async function saveSelectedType(selectedType) {
    setSelectedType(selectedType);
    await saveSelectionStates(selectedGender, selectedShape, selectedType);
  }

  async function saveSelectedGender(selectedGender) {
    setSelectedGender(selectedGender);
    await saveSelectionStates(selectedGender, selectedShape, selectedType);
  }

  async function resetFilter(){
    await saveSelectionStates(false,false,false,false);
  }

  async function saveFilteredList(filteredList) {
    setFilteredGlassesList(filteredList);
    await saveFilteredListDB(filteredList);
  }

  async function updatePageStateInDB(
    isBrandPage,
    isSingleBrandPage,
    isSingleGlassesPage,
    startSelectionLanguage,
    filterdByGender,
    filterdeByShapes,
    filterByType
  ) {
    setIsBrandPage(isBrandPage ? isBrandPage : false);
    setIsSingleBrandPage(isSingleBrandPage ? isSingleBrandPage : false);
    setIsSingleGlassesPage(isSingleGlassesPage ? isSingleGlassesPage : false);
    setStartSelectionLanguage(
      startSelectionLanguage ? startSelectionLanguage : false
    );
    setIsFilteredByGender(filterdByGender ? filterdByGender : false);
    setIsFiltereByShape(filterdeByShapes ? filterdeByShapes : false);
    setIsFiltereByType(filterByType ? filterByType : false);
    savePageStates(
      isBrandPage,
      isSingleBrandPage,
      isSingleGlassesPage,
      startSelectionLanguage,
      filterdByGender,
      filterdeByShapes,
      filterByType
    );
  }

  useEffect(() => {
    async function loadCatalogsAndGlasses() {
      setLoadingProgress(10); // Aggiorna il progresso dopo l'inizio del processo
      console.log("LOAD DATA 1")

      let {
        catalogsData,
        glassesData,
        selectedVariantData,
        selectedBrandData,
        selectedGlassesData,
        isNewSessionData,
        filteredListData,
      } = await loadData();

      console.log("LOAD DATA 4")

      const states = await loadPageStates();

      console.log("LOAD DATA 5")

      const selectionState = await loadSelectionStates();

      console.log("Dati caricati da IndexedDB:", catalogsData, glassesData);
      console.log("PAGE STATE SELECTED Contest", states);
      console.log("SELECTION STATE SELECTED Contest", selectionState);
      console.log("FILTERED LIST", filteredListData);
      setIsBrandPage(states?.isBrandPage ? states.isBrandPage : false);
      setIsSingleBrandPage(
        states?.isSingleBrandPage ? states.isSingleBrandPage : false
      );
      setIsSingleGlassesPage(
        states?.isSingleGlassesPage ? states.isSingleGlassesPage : false
      );
      setStartSelectionLanguage(
        states?.startSelectionLanguage ? states.startSelectionLanguage : false
      );
      setIsFilteredByGender(
        states?.filteredBygender ? states.filteredBygender : false
      );
      setIsFiltereByShape(
        states?.filterdeByShapes ? states.filterdeByShapes : false
      );
      setIsFiltereByType(
        states?.isFilteredByType ? states.isFilteredByType : false
      )

      if (selectionState) {
        setSelectedGender(selectionState.selectedGender);
        setSelectedShape(selectionState.selectedShape);
        setSelectedType(selectionState.selectedType);
      }

      if (!catalogsData || !glassesData) {

        // console.log("FIRST Loading");

        try {

          const catalogsData = await fetchCatalogsFromDB(c);
          setCatalogs(catalogsData);

          setLoadingProgress(30); // Aggiorna il progresso dopo il caricamento dei cataloghi

          const glassesContentList = loadMockGlassesContent();
          setLoadingProgress(40); // Aggiorna il progresso dopo il caricamento dei contenuti degli occhiali

          let allGlasses = [];
          for (const catalog of catalogsData) {
            for (const lineRef of catalog.listaRefsLinea) {
              const line = await getDoc(lineRef.withConverter(lineaConverter));
              const glassesPromises = line
                .data()
                .listaRefsOcchiale.map((glassesRef) =>
                  fetchGlassesDetails(glassesRef, line.data(), catalog)
                );
              setLoadingProgress(70); // Aggiorna il progresso dopo il caricamento dei contenuti degli occhiali

              const glassesForLine = (
                await Promise.all(glassesPromises)
              ).filter((glasses) => glasses !== null);

              allGlasses = [...allGlasses, ...glassesForLine];
            }
          }

          allGlasses = allGlasses.map(glasses => {
            // Controlla se il brandId corrisponde al valore specificato
            if (glasses.brandId === 'Nqyd5EKyrBb3fufpJyKS') {
              // Se sì, crea una copia dell'oggetto glasses con il brandId aggiornato
              return { ...glasses, brandId: 'qmDTd8ciaSs31akT3Eqq' };
            }

            if (glasses.brandId === 'USamwu7Ywd2oNAU5eL50') {
              // Se sì, crea una copia dell'oggetto glasses con il brandId aggiornato
              return { ...glasses, brandId: 'rBf3geYISXtkue0WGgFT' };
            }
            // Se il brandId non corrisponde, restituisce l'oggetto inalterato
            return glasses;
          });

          allGlasses = allGlasses.map((glasses) => {
            setLoadingProgress(80); // Aggiorna il progresso dopo il caricamento dei contenuti degli occhiali

            const matchingContent = glassesContentList.find(
              (content) => content.id === glasses.id
            );
            return matchingContent
              ? { ...glasses, glassesContent: matchingContent }
              : glasses;
          });

          setListOfGlasses(shuffleArray(allGlasses));
          setLoadingProgress(90); // Aggiorna il progresso dopo il caricamento dei contenuti degli occhiali

          console.log(catalogsData,allGlasses );
           await saveData(catalogsData, allGlasses);
          // setLoadingProgress(100); // Aggiorna il progresso dopo il caricamento dei contenuti degli occhiali

        } catch (error) {
          console.error(
            "Errore nel caricamento dei cataloghi e degli occhiali:",
            error
          );
          setLoadingProgress(100); // Anche in caso di errore, considera completato il caricamento
        }
      }
       else {
        setLoadingProgress(50); // Aggiorna il progresso dopo il caricamento dei contenuti degli occhiali

        if (selectedVariantData) {
          // console.log("VARIANT SELECTED Contest" , selectedVariantData)
          setSelectedVariant(selectedVariantData);
        }
        if (selectedBrandData) {
          // console.log("BRAND SELECTED Contest" , selectedBrandData)
          setSelectedBrand(selectedBrandData);
        }
        if (selectedGlassesData) {
          // console.log("GLASSES SELECTED Contest" , selectedGlassesData)
          setSelectedGlasses(selectedGlassesData);
          if (isNewSessionData) {
          setIsNewSession(isNewSessionData);
          }
        }
        setCatalogs(catalogsData);
        setListOfGlasses(shuffleArray(glassesData));
        setFilteredGlassesList(filteredListData);
        if(filteredListData){
          const typeCategory = getAllFormCategories(filteredListData,"Tipologia")
          setAllType(typeCategory);
          console.log(typeCategory);
          const formCategories = getAllFormCategories(filteredListData,"Forma");
          setAllShapes(formCategories);

        }
      }
      setLoadingProgress(99); // Aggiorna il progresso dopo il caricamento dei contenuti degli occhiali

      setTimeout(()=>{
        setLoadingProgress(100); // Aggiorna il progresso dopo il caricamento dei contenuti degli occhiali
      },200)
    }

    loadCatalogsAndGlasses();
  }, [c]);

  return (
    <CatalogDataContext.Provider
      value={{
        catalogs,
        listOfGlasses,
        loadingProgress,

        //Function
        filterGlassesByGender,
        //Boolean
        isfilteredByGender,

        // list Tags
        listOfTags,
        // Function for get categories
        getAllFormCategories,

        //function for filtered
        filterGlassesByForm,
        //Boolean
        isFilteredByShape,
        isFilteredByType,

        filteredGlassesList,
        saveFilteredList,

        //function for set Glasses
        saveSelectedGlasses,
        //Glasses Selected
        selectedGlasses,

        //function for set Variant
        saveSelectedVariant,
        //Variant Selected
        selectedVariant,

        //function for set Brand
        saveSelectedBrand,
        //Brand Selected
        selectedBrand,

        //Utils Fanction
        getCatalogById,
        getGlassesByBrand,
        getGlassesByBrandFiltered,
        getAllVariant,

        filterGlasses,

        clearSession,
        setStartNewSession,
        isNewSession,

        //PAGE STATE
        updatePageStateInDB,
        isBrandPage,
        isSingleBrandPage,
        isSingleGlassesPage,
        startSelectionLanguage,

        //UTILITY AND OBJECT SELECTED
        selectedGender,
        selectedShape,
        selectedType,
        saveSelectedShape,
        saveSelectedGender,
        saveSelectedType,
        resetFilter,
        allShapes,
        setAllShapes,
        allType,
        setAllType
      }}
    >
      {children}
    </CatalogDataContext.Provider>
  );
};

const loadMockGlassesContent = () => {
  const mocks = [mockGlassesData1, mockGlassesData2, mockGlassesData3];
  return mocks.map((mock) => new GlassesContent(mock, mock.id));
};

async function fetchCatalogsFromDB(c) {
  try {
    // Supponiamo che tu abbia una lista di stringhe dei cataloghi chiamata `catalogStrings`
    const catalogStrings = c; // Inserisci qui tutte le stringhe dei tuoi cataloghi

    let brandsList = [];

    // Cicla su ogni stringa del catalogo e carica il brand corrispondente
    for (let c of catalogStrings) {
      const brandRefd = doc(db, "Brand", c).withConverter(brandConverter);
      const docBrand = await getDoc(brandRefd);
      let brandData = docBrand.data();

      if (brandData.nome_brand === "POLAROID"|| brandData.nome_brand === "POLAROID REV") {
        const mockBrandDataPolaroid = new BrandContent(
          mockBrandData1,
          mockBrandData1.id
        );
        brandData.brandContent = mockBrandDataPolaroid;
        console.log("Content Brand", mockBrandDataPolaroid.toString())
      }
      if (brandData.nome_brand === "CARRERA" || brandData.nome_brand === "CARRERA REV") {
        const mockBrandDataPolaroid = new BrandContent(
          mockBrandData2,
          mockBrandData2.id
        );
        brandData.brandContent = mockBrandDataPolaroid;
        console.log("Content Brand", mockBrandDataPolaroid.toString())
      }
      if (brandData.nome_brand === "BOSS EYEWEAR") {
        const mockBrandDataPolaroid = new BrandContent(
          mockBrandData3,
          mockBrandData3.id
        );
        brandData.brandContent = mockBrandDataPolaroid;
        console.log("Content Brand", mockBrandDataPolaroid.toString())
      }
      if (brandData.nome_brand === "Spaarkly Demo") {
        const mockBrandDataPolaroid = new BrandContent(
          mockBrandData4,
          mockBrandData4.id
        );
        brandData.brandContent = mockBrandDataPolaroid;
        console.log("Content Brand", mockBrandDataPolaroid.toString())
      }

      // Aggiungi il brand caricato alla lista dei brands
      brandsList.push(brandData);
    }

    return brandsList;
  } catch (error) {
    console.log(error);
    throw error;
  }
}

async function fetchGlassesDetails(glassesRef, line, catalog) {
  const glassesDoc = await getDoc(glassesRef.withConverter(occhialeConverter));
  let sizeGlasses = [];
  let tagList = [];
  let tagShapesList = [];

  // Carica i badge
  const badgePromises = glassesDoc.data().lista_categorie.map(async (tag) => {
    const tagRefFB = await getDoc(tag.withConverter(tagConverter));
    tagList.push(tagRefFB.data());
  });

  // Carica le taglie e i modelli
  const sizePromises = glassesDoc.data().lista_taglie.map(async (size) => {
    const sizeRefFB = await getDoc(size.withConverter(tagliaConverter));
    let modelList = [];

    const modelPromises = sizeRefFB.data().listaRefModels.map(async (model) => {
      const modelRefFB = await getDoc(model.withConverter(modelloConverter));
      modelList.push(modelRefFB.data());
    });

    await Promise.all(modelPromises);
    let sizeModel = sizeRefFB.data();
    sizeModel.listaModelli = modelList;
    sizeGlasses.push(sizeModel);
  });

  await Promise.all([...badgePromises, ...sizePromises]);

  let glassesData = glassesDoc.data();
  glassesData.listaCategorieObj = tagList;
  glassesData.listaTaglieOBJ = sizeGlasses;
  glassesData.lineaObj = line;
  glassesData.brandId = catalog.id;
  // Aggiungi qui il controllo se gli occhiali sono validi
  if (glassesData.isValidGlasses()) {
    return glassesData;
  }

  return null; // o gestisci in modo diverso gli occhiali non validi
}


