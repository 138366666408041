import React , {useRef, useEffect} from 'react';
import i18n from 'i18next';
import { CSSTransition , TransitionGroup} from 'react-transition-group';
import "../../Utils/Transition.css"
import ButtonWithIcon from './ButtonWithIcon';
import FlexContainer from '../container/FlexContainerVertical';
import IMAGES from '../../data/images';
import gsap from 'gsap';
import MirrorTittle from './MirrorHeader';

function RootSelector({onSelectLanguage}) {
    
  const mirrorTitleRef = useRef(null);
  const buttonRef = useRef([]);


    useEffect(() => {
      console.log("IN ROOT SELECTOR")
      gsap.fromTo(
        mirrorTitleRef.current,
        { x: 600, rotationY: -90, z: 500 },
        { x: 0, rotationY: 0, z: 0, duration: 1, ease: "power3.out" }
      );
        // Animazione di Ingresso
        buttonRef.current.forEach((el, index) => {
          if (index === buttonRef.current.length - 1) {
            gsap.fromTo(el, 
              { x: 600, z: 500, rotationY: 140 }, // animazione specifica per l'ultimo bottone
              { x: 0, z: 0, rotationY: 0, duration: 0.5, delay: 0.1 * index, ease: "power3.out" }
            );
          } else {
            gsap.fromTo(el, 
              { x: -600, z: 500, rotationY: 140 }, // animazione standard per gli altri bottoni
              { x: 0, z: 0, rotationY: 0, duration: 0.5, delay: 0.1 * index, ease: "power3.out" }
            );
          }
        });
        
    
    }, []); // Dipendenza da startSelectionLanguage
    
    function setOnSelectedLanguage(language){

      if(!(language === "experience" || language ==="onlineShop")){

      gsap.to(mirrorTitleRef.current, {
        x: -600, 
        rotationY: 90, 
        z:500 ,
      duration: 1,
      ease: "power3.out",
    });
    
    buttonRef.current.forEach((el, index) => {
        gsap.to(el, {
          x: 600, z: 500, rotationY: -140, duration: 0.5, delay: 0.1 * index, ease: "power3.in",
          onComplete: () => {
            if (index === buttonRef.current.length - 1) {
              onSelectLanguage(language);
            }
          }
        });
      
    });
    
    }else {
      onSelectLanguage(language);
    }
  }

    const buttons = [
      { language: 'brandPage', label: 'discover the Event', icon: IMAGES.itl_icon },
      { language: 'experience', label: 'polaroid experience', icon: IMAGES.itl_icon },
      { language: 'glassesPage', label: 'try glasses', icon: IMAGES.esp_icon },

      { language: 'storeLocator', label: 'Find A Store', icon: IMAGES.eng_icon} ,
      { language: 'onlineShop', label: 'online shop', icon: IMAGES.dut_icon} ,
      
    ];

    function generateRandomRef() {
      return `ref_${Math.random().toString(36).substr(2, 9)}`;
    }
    

    return (
   
       <>
<div className='root-selector-header'>
         {/* <div className='logo-header-root'> */}
         <img className='logo-header-root-img' src="https://firebasestorage.googleapis.com/v0/b/arshades-7e18a.appspot.com/o/FCMImages%2FLogoPolaroid%20(1)%20(1).png?alt=media&token=614e8a59-cd4b-4e8d-b254-253a462a5996"></img> 
        {/* </div> */}
        </div>
       <FlexContainer>
        
        {/* <img className='mvp-logo-homepage' src="https://firebasestorage.googleapis.com/v0/b/arshades-7e18a.appspot.com/o/immagini%2Fbrand_logo%2F3.png?alt=media&token=b6eb5773-aff2-4f78-b072-224fa9e5c558"></img> */}
       <MirrorTittle  ref={mirrorTitleRef} title="10 July - 13 July" subtitle="" skip={false} />
        <div className='regular-space'></div>
        {buttons.slice(0, buttons.length - 2).map((button, index) => (
        <ButtonWithIcon
          ref={el => buttonRef.current[index] = el}
          key={button.language}
          language={button.language}
          label={button.label}
          onSelectLanguage={setOnSelectedLanguage}
          boxButton={false}
        />
      ))}
      <div className={"flex-container-button"}>
      {buttons.slice(buttons.length - 2, buttons.length).map((button, index) => (
        <ButtonWithIcon
        ref={el => buttonRef.current[index + buttons.length - 2] = el} // Utilizzo di useRef per il riferimento
        key={button.language}
          language={button.language}
          label={button.label}
          onSelectLanguage={setOnSelectedLanguage}
          boxButton={true}
        />
      ))}
      </div>
           
            </FlexContainer>
        </>
    );
}

export default RootSelector;
