import React, { Children, useEffect, useState,useRef } from 'react';
import CardContainerTwoC from '../container/CardContainerTwoC';
import CardButton from './CardButton';
import MirrorGlassesCard from './MirrorGlassesCard';
import BrandHeader from './BrandHeader';
import MirrorTittle from './MirrorHeader';
import styles from './BrandHeader.module.css'
import { useTranslation } from 'react-i18next';
import gsap from "gsap";
import CardContainerHorizontal from '../container/CardContainerHorizontal';
import MirrorGlassesCardHorizontal from './MirrorGlassesCardHorizontal';

function SingleBrandPage({ selectedBrand, translate ,listOfGlasses, handleCardClick}) {
    const { t } = useTranslation();

    const [currentVideo, setCurrentVideo] = useState('');

    const [selectedVideo, setSelectedVideo] = useState(        { url: "https://firebasestorage.googleapis.com/v0/b/arshades-7e18a.appspot.com/o/storage%2Fvideo%2FCARDUC005S_VIDEO.mp4?alt=media&token=eb351ebc-486c-4c49-8964-078ad1616e12", title: "explosion" },
    );

    
    const cardButtonRefs = useRef([]);
    const containerCard = useRef(null);

    function animateOut(glasses) {
        // Animazione di uscita


        handleCardClick(glasses)

 
}

    useEffect(()=>{
        if(selectedBrand?.brandContent?.contentList[0]?.media){
            setCurrentVideo(selectedBrand.brandContent.contentList[0].media);
        }   
     }, [selectedBrand])

     const handleVideoEnd = () => {
        // Cambia il video quando uno termina
        setCurrentVideo(current => 
            current === selectedBrand?.brandContent?.contentList[0]?.media
            ? selectedBrand?.brandContent?.contentList[0]?.media2 
            : selectedBrand?.brandContent?.contentList[0]?.media);
    };

    const ARShadesVideoUrls = [
        { url: "https://firebasestorage.googleapis.com/v0/b/arshades-7e18a.appspot.com/o/storage%2Fvideo%2FCARDUC005S_VIDEO.mp4?alt=media&token=eb351ebc-486c-4c49-8964-078ad1616e12", title: "explosion" },
        { url: "https://firebasestorage.googleapis.com/v0/b/arshades-7e18a.appspot.com/o/storage%2Fvideo%2FCARRERA%208061CS%20-%20clip%20on_high.mp4?alt=media&token=c3577fbd-e66a-4c86-988c-5026d4017c35", title: "Clip On Animation" },
        { url: "https://firebasestorage.googleapis.com/v0/b/arshades-7e18a.appspot.com/o/storage%2Fvideo%2FCARRERA315S_VIDEO.mp4?alt=media&token=49697a89-6e64-4a75-b4c4-11d3ac53a80b", title: "teardown " },
        { url: "https://firebasestorage.googleapis.com/v0/b/arshades-7e18a.appspot.com/o/storage%2Fvideo%2FCARRERA316S_VIDEO.mp4?alt=media&token=facdf349-d12a-49a2-8fd6-12ae20dba442", title: "explosion two" },
        { url: "https://firebasestorage.googleapis.com/v0/b/arshades-7e18a.appspot.com/o/storage%2Fvideo%2FPLD2125S_surr.mp4?alt=media&token=3f81648a-94c2-44f7-926f-9606350d66f2", title: "Environment " },

        
        
        
        // ... altri oggetti
    ];
    

    return (
        <>
        {selectedBrand && <BrandHeader title={"Your Event Name"} text={selectedBrand?.brandContent?.contentList[0]?.descrizione} />}

        {selectedBrand.nome_brand !== "Spaarkly Demo" ? (
              <div className={styles.videoContainer}>
              {/* <div className={styles.playButton}>
                  ▶
              </div> */}
              <video
                  controls={true}
                  className={styles.video}
                  autoPlay
                  muted
                  loop={false}  // Imposta loop a false
                  onEnded={handleVideoEnd}  // Aggiungi il gestore dell'evento onEnded
                  src={currentVideo}
              >
                  <source src={selectedBrand?.brandContent.contentList[0].media} type="video/mp4" />
                  Your browser does not support the video tag.
              </video>
          </div>
        ) : (

            <div className={styles.videoContainerThree}>

                <div className={styles.titlesContainer}>

                {ARShadesVideoUrls.map((video, index) => (
                    <h3
                        key={index}
                        onClick={() => setSelectedVideo(video)}
                        className={video.url === selectedVideo.url ? styles.selectedTitle : ''}
                    >
                        {video.title}
                    </h3>
                ))}
                </div>
                    <video src={selectedVideo.url} autoPlay loop controls className={styles.videoTwo}/>
            </div>

        )}
      

        <MirrorTittle title={t("glasses")} skip={false}/>



        {/* <CardContainerTwoC margin={"10px"}>
                    
                    {listOfGlasses.map(
                                      (glasses, index) => (
                                        <MirrorGlassesCard
                                            key={index}
                                            glasses={glasses}
                                            onCardClick={handleCardClick}
                                          />
                                        
                                      )
                                    )}

         </CardContainerTwoC> */}

         <CardContainerHorizontal margin={"10px"} ref={containerCard}>
        {listOfGlasses
          .filter(glasses => glasses.listaTaglieOBJ[0]?.listaModelli[0]?.image3D) // Filtro gli occhiali con image3D non vuoto o nullo
          .map((glasses, index) => (
          <MirrorGlassesCardHorizontal
            key={index}
            glasses={glasses}
            onCardClick={animateOut}
            ref={(el) => cardButtonRefs.current[index] = el}          />
        ))}
      </CardContainerHorizontal>

      <div className='space'></div>
        </>
    );
}

export default SingleBrandPage;