class Modello {
    constructor(doc, id){
        this.id = id;
        this.coloreLenti = doc.coloreLenti;
        this.nomeOcchiale = doc.nomeOcchiale;
        this.codiceVariante = doc.codiceVariante;
        this.coloreFrame = doc.coloreFrame;
        this.esaColorLensesPrimary = doc.esaColorLensesPrimary;
        this.sku_model = doc.sku_model;
        this.lista_note = doc.lista_note;
        this.stato = doc.stato;
        this.urlGlb = doc.urlGlb;
        this.urlDE = doc.urlDE;
        this.coloreAste = doc.coloreAste;
        this.loadingId = doc.loadingId;
        this.poster = doc.poster;
        this.poster3 = doc.poster3;
        this.esaColorFrameSecondary = doc.esaColorFrameSecondary;
        this.esaColorFramePrimary = doc.esaColorFramePrimary;
        this.urlGlbComplete = doc.urlGlbComplete;
        this.urlES = doc.urlES;
        this.urlUsdz = doc.urlUsdz;
        this.urlUsdzComplete = doc.urlUsdzComplete;
        this.lastUpdate = doc.lastUpdate;
        this.urlFR = doc.urlFR;
        this.poster4 = doc.poster4;
        this.urlProductPage = doc.urlProductPage;
        this.esaColorLensesSecondary = doc.esaColorLensesSecondary;
        this.url_product_page = doc.url_product_page;
        this.esaColorFramePrimary = doc.esaColorFramePrimary;
        this.poster2 = doc.poster2;
        this.image3D = doc['3DImage']; // Usa la notazione con le parentesi quadre
    }

    toString(){
        return `URL_GLB: ${this.urlGlb} ,Modello: ${this.nomeOcchiale}, Colore Lenti: ${this.coloreLenti}, Colore Frame: ${this.coloreFrame}, Codice Variante: ${this.codiceVariante}`;
    }
}

  //FIrestore Data Converter
 export const modelloConverter ={
    toFirestore: (modello) => {
        return {
          
        }
    },
    fromFirestore: (snapshot, options) => {
         const data = snapshot.data(options);
        return new Modello(data, snapshot.id);
    }
  };