import * as THREE from "three";

import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import { WATERMARK } from "../Utils/constant";
import { isMobile } from "react-device-detect";
import OneEuroFilter from "../component/OneEuroFilter";
import { getDoc, updateDoc } from "@firebase/firestore";
import { all } from "axios";


export default class EffectRenderer {
  constructor(canvasElement, size) {
    this.VIDEO_DEPTH = 310;
    this.FOV_DEGREES = 63;
    this.NEAR = 1;
    this.FAR = 10000;
    this.canvaElement = canvasElement;
    this.scene = new THREE.Scene();
    this.renderer = new THREE.WebGLRenderer({
      canvas: canvasElement,
      // alpha: true,
      antialias: true,
      precision: "highp",
      logarithmicDepthBuffer: true,
      stencilBuffer: true,
      preserveDrawingBuffer: true,
      //    context: canvasElement?.current.getContext("2d")
    });
    this.dimension = { width: size.width, height: size.height };
    this.renderer.shadowMap.enabled = false;
    this.renderer.gammaFactor = 1;
    this.renderer.gammaOutput = true;
    this.renderer.toneMappingExposure = 20.0;
    this.renderer.outputColorSpace = THREE.SRGBColorSpace;
    this.fotoTemplate = null;
    this.animations = null;

    const targetObject = new THREE.Object3D();
    targetObject.position.set(0, 0, 0);
    this.scene.add(targetObject);
    // const directionalLight = new THREE.DirectionalLight(0xffffff, 1.6);
    // directionalLight.castShadow = true;
    // directionalLight.position.set(0, 3, 2);
    // directionalLight.target = targetObject;
    //  this.scene.add(directionalLight);
    const bounceLight = new THREE.HemisphereLight(0xffffff, 0xffffff, 0.1);
    //  this.scene.add(bounceLight);
    this.faceGroup = new THREE.Group();
    this.faceGroup.matrixAutoUpdate = false;
    this.scene.add(this.faceGroup);
    this.loader = new GLTFLoader();
    this.model3D = null;
    this.camera = new THREE.PerspectiveCamera(
      this.FOV_DEGREES,
      this.dimension.width / this.dimension.height,
      this.NEAR,
      this.FAR
    );
    // this.camera = new THREE.PerspectiveCamera(this.FOV_DEGREES, window.innerWidth > window.innerHeight ? window.innerWidth / window.innerHeight  : window.innerHeight > window.innerWidth  , this.NEAR, this.FAR);
    this.brandLogo = null;
    // this.camera.aspect = window.innerWidth / window.innerHeight;
    this.camera.updateProjectionMatrix();

    this.countdata = 0;
    this.env = new RGBELoader();
    this.texture = null;
    this.coeffX = null;
    this.coeffY = null;
    this.coeffZ = null;
    this.saveRequest = false;
    this.image = null;
    this.index = 6;
    this.indexSet = 6;
    this.count = 0;
    this.branDataHdr = null;
    this.cache = {};

    this.Singleconsumption = 0;
    this.consumption = 0;
    this.call = 0;
    this.filters = new OneEuroFilter({ minCutOff: 0.003, beta: 5 });
    this.textureLoader = new THREE.TextureLoader();

    this.isPlayed = false;
    this.mixer = null;
    this.clock = new THREE.Clock();

    this.isAnimated = false;
    // console.log("set dimension",this.dimension.width);
  }

  removeAllChildrenFromFaceGroup() {
    while (this.faceGroup.children.length > 0) {
      this.faceGroup.remove(this.faceGroup.children[0]);
    }
  }


  setStartEffect(value) {
    this.effectRenderer = value;
    this.handPositionsHistory = [];

  }



  loadModel(variantUrl) {
    this.removeAllChildrenFromFaceGroup();
    if (variantUrl in this.cache) {
      const cachedData = this.cache[variantUrl];

      const cachedModel = cachedData.model
      const cacheCanonical = this.cache["https://webvto.it/model/canonical.glb"];
      let animations = cachedData.animations; 

      if(animations && animations.length > 0){
        if (this.animations[0].name !== "flex_glasses_an" && this.isPlayed) {

        console.log("ANIMAZIONE PRESENTE");
        this.isAnimated = true;
        this.isPlayed = false;
        this.animations = animations;
        this.mixer = new THREE.AnimationMixer(cachedModel);
        const action = this.mixer.clipAction(this.animations[0]); // Sostituisci con l'indice dell'animazione desiderata
        action.play();
        action.paused = true;
        }
      }

      this.faceGroup.add(cachedModel); // Cloniamo il modello per evitare effetti collaterali
      this.faceGroup.add(cacheCanonical);
      this.model3D = cachedModel;
      return;
    } else {
      this.call = 0;
    }

    this.loader.load("https://webvto.it/model/canonical.glb", (glt2f) => {
      //console.log(glt2f);

      const scene2 = glt2f.scene;
      scene2.position.set(0, 0, -0.45);
      // console.log("load Model");
      if (
        this.branDataHdr !== null &&
        typeof this.branDataHdr !== "undefined" &&
        this.env !== null
      ) {
        // console.log("load hdr", this.branDataHdr)
        this.env.load(this.branDataHdr, (texture) => {
          texture.mapping = THREE.EquirectangularReflectionMapping;
          this.scene.background = texture;
          this.scene.environment = texture;
          texture.mapping = THREE.EquirectangularReflectionMapping;
          // texture.flipY = true;
          // texture.flipX = true;
          let pmremGenerator = new THREE.PMREMGenerator(this.renderer);
          pmremGenerator.compileEquirectangularShader();
          let envMap = pmremGenerator.fromEquirectangular(texture).texture;
          this.scene.environment = envMap;
          pmremGenerator.dispose();
        });
      } else {
        this.env.load(
          "https://firebasestorage.googleapis.com/v0/b/arshades-7e18a.appspot.com/o/hdr%2Ffarm_field_puresky_1k.hdr?alt=media&token=817e5bb9-9bab-4696-9fb2-5a4f97e3d72b",
          (texture) => {
            texture.mapping = THREE.EquirectangularReflectionMapping;
            this.scene.background = texture;
            this.scene.environment = texture;
            // this.renderer.encoding = THREE.SRGBColorSpace;
            texture.mapping = THREE.EquirectangularReflectionMapping;
            // texture.flipY = true;
            let pmremGenerator = new THREE.PMREMGenerator(this.renderer);
            pmremGenerator.compileEquirectangularShader();
            let envMap = pmremGenerator.fromEquirectangular(texture).texture;
            this.scene.environment = envMap;
            pmremGenerator.dispose();
          }
        );
      }

      if (variantUrl) {
        // console.log("scene",variantUrl);

        let pesoModello = 0;
        this.loader.load(
          variantUrl,
          (gltf) => {
            const model2 = gltf.scene;
            model2.name = 'model2UniqueName';
            if (!this.faceGroup.getObjectByName('model2UniqueName')) {
            model2.scale.set(100, 100, 100);
            model2.traverse((node) => {
              if (node.isMesh) {
                node.renderOrder = 2;

                // Creare un nuovo materiale fisico basato sul materiale esistente
                const oldMaterial = node.material;

                let newMaterialProperties = {
                  color: oldMaterial.color,
                  map: oldMaterial.map,
                };

                // Controllo e mappatura delle proprietà se esistono nel materiale originale
                if (oldMaterial.roughnessMap)
                  newMaterialProperties.roughnessMap = oldMaterial.roughnessMap;
                if (oldMaterial.metalnessMap)
                  newMaterialProperties.metalnessMap = oldMaterial.metalnessMap;
                if (oldMaterial.normalMap)
                  newMaterialProperties.normalMap = oldMaterial.normalMap;
                if (oldMaterial.alphaMap)
                  newMaterialProperties.alphaMap = oldMaterial.alphaMap;
                if (oldMaterial.emissiveMap)
                  newMaterialProperties.emissiveMap = oldMaterial.emissiveMap;
                if (oldMaterial.hasOwnProperty("roughness"))
                  newMaterialProperties.roughness = oldMaterial.roughness;
                if (oldMaterial.hasOwnProperty("metalness"))
                  newMaterialProperties.metalness = oldMaterial.metalness;
                if (oldMaterial.hasOwnProperty("transparent"))
                  newMaterialProperties.transparent = oldMaterial.transparent;
                if (oldMaterial.hasOwnProperty("opacity"))
                  newMaterialProperties.opacity = oldMaterial.opacity;

                // Creazione del nuovo materiale e sostituzione del vecchio
                const newMaterial = new THREE.MeshPhysicalMaterial(
                  newMaterialProperties
                );
                node.material = newMaterial;
              }
            });
            this.model3D = model2;
            model2.position.set(0, 100.88203763961792, 500.639305114746094);
            // console.log("ANIMAZIONE PRESENTE", gltf.animations);
            if(gltf.animations && gltf.animations.length > 0){
              this.isPlayed = false;

              if (gltf.animations[0].name !== "flex_glasses_an") {
                console.log("ANIMAZIONE PRESENTE",gltf.animations[0].name);

                this.isAnimated = false;
              }

              this.animations = gltf.animations;
              this.mixer = new THREE.AnimationMixer(this.model3D);
              const action = this.mixer.clipAction(this.animations[0]); // Sostituisci con l'indice dell'animazione desiderata
              action.play();
              action.paused = true;
            }
            this.cache[variantUrl] = {
              model: model2.clone(),
              animations: gltf.animations // Assumi che gltf.animations sia un array di AnimationClips
            };           
              this.faceGroup.add(model2);
          }
          },

        );

        scene2.scale.set(100, 100, 100);
        scene2.traverse((node) => {
          if (node.isMesh) {
            node.renderOrder = 1;
            const mat = new THREE.MeshPhongMaterial();
            mat.color.set(0xffffff);
            mat.colorWrite = false;
            node.material = mat;
          }
        });

        this.cache["https://webvto.it/model/canonical.glb"] = scene2;

        this.faceGroup.add(scene2);
      }
    });
  }

  setUrl(variantUrl) {
    this.url = variantUrl;
    this.faceGroup.clear();
    this.loadModel(variantUrl);
  }

  setIndex(selectedIndex) {
    this.index = selectedIndex;
  }


  setCoeFF(coeffX, coeffY, coeffZ) {
    this.coeffX = coeffX;
    this.coeffY = coeffY;
    this.coeffZ = coeffZ;
  }

  setSaveRequest() {
    this.saveRequest = true;
  }

  setBrandLogo(url) {
    // console.log(url);
    this.brandLogo = url;
  }

  setBrandDataHdr(hdr) {
    this.branDataHdr = hdr;
  }

  async setTakePicTemplate(fotoTemplate) {
    this.fotoTemplate = fotoTemplate;
  }

  saveFrame() {
    // console.log("template in effect", this.fotoTemplate);

    const canvas = document.createElement("canvas");
    if (isMobile) {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    } else {
      canvas.width = this.dimension.width / 2;
      canvas.height = this.dimension.height / 2;
    }

    const ctx = canvas.getContext("2d");

    // Disegna l'immagine sul canvas
    const img = new Image();
    img.src = this.renderer.domElement.toDataURL();
    img.onload = () => {
      const scale = Math.max(
        canvas.width / img.width,
        canvas.height / img.height
      );

      // Calcola le dimensioni dell'immagine ridimensionata
      const scaledWidth = img.width * scale;
      const scaledHeight = img.height * scale;

      // Calcola l'offset per centrare l'immagine
      const offsetX = (canvas.width - scaledWidth) / 2;
      const offsetY = (canvas.height - scaledHeight) / 2;

      ctx.drawImage(img, offsetX, offsetY, scaledWidth, scaledHeight);

      // Disegna i banner e i watermark se necessario

      // console.log("Watermark", this.fotoTemplate)
      // Ripeti il processo per bt_enabled, bt_watermark_img, e così via...
      if (this.fotoTemplate.areWatermarksLoaded()) {
        if (this.fotoTemplate.bb_enabled) {
          drawBannerAndWatermark(
            ctx,
            this.fotoTemplate,
            "bb",
            canvas.height - this.fotoTemplate.bb_height,
            canvas
          );
        }

        if (this.fotoTemplate.bt_enabled) {
          // drawBannerAndWatermark(ctx, this.fotoTemplate, "bt", 0, canvas);
        }
      } else {
        // console.error('Watermarks are not loaded yet.');
      }

      // Converti il canvas in un'immagine e restituiscilo
      const strMime = "image/jpeg";
      const imgData = canvas.toDataURL(strMime);
      const strDownloadMime = "image/octet-stream";
      const strData = imgData.replace(strMime, strDownloadMime);

      this.image = strData;
    };
  }

  // Funzione per controllare e avviare l'animazione
playAnimation() {
  // console.log("PLAY ANIMATION")
  if (this.model3D && this.animations) {
    this.mixer.removeEventListener('finished');
    const action = this.mixer.clipAction(this.animations[0]);
    action.reset();

    if (!this.isPlayed) {
      // Prima riproduzione: avvia l'animazione normalmente
      action.setLoop(THREE.LoopOnce);
      action.timeScale = 1; // Velocità normale
      // action.time = action.getClip().duration; // Imposta il tempo all'ultimo frame
      action.time = 0;
      action.play();

      this.mixer.addEventListener('finished', (e) => {
        if (e.action === action) {
          console.log("Animazione completata");
          this.isPlayed = true; // Imposta lo stato a "giocato"
        }
      });
    } 
    this.mixer.update(0); // Aggiorna con un delta time di 0
  }
}

reversePlayAnimation() {
  // console.log("PLAY ANIMATION")
  if (this.model3D && this.animations) {
    this.mixer.removeEventListener('finished');

    const action = this.mixer.clipAction(this.animations[0]);
    action.reset();

    if (this.isPlayed) {
      action.setLoop(THREE.LoopOnce);
      action.timeScale = -1;
      action.time = action.getClip().duration;
      action.play();
  
      const onFinished = (e) => {
          if (e.action === action) {
              console.log("Animazione Riversa completata");
  
              // Assicurati che action sia ancora valido e riferisca all'animazione corretta
              if (action) {
                  // Imposta il tempo a 0 prima di fermare l'animazione
                  // console.log("ACTION", action)
                  // action.timeScale = 1;
                  action.time = 0;
                  action.reset(); // Usa reset anziché stop se disponibile
                  action.paused = true;
                //   setTimeout(() => {
                //     action.reset(); // Usa reset anziché stop se disponibile
                //     action.paused = true;
                // }, 0);

                  // action.play();
                  // action.paused = true;
                  // action.reset(); // Usa reset anziché stop se disponibile
  
                  this.isPlayed = false;
              }
  
              this.mixer.removeEventListener('finished', onFinished);
          }
      };
  
      this.mixer.addEventListener('finished', onFinished);
  }
 
    this.mixer.update(0); // Aggiorna con un delta time di 0
  }
}

  render(results, lastHandResults, scaledImage) {
    if (this.saveRequest) {
      this.saveFrame();
      this.saveRequest = false;
    }
    this.onCanvasDimsUpdate(scaledImage);
    const imagePlane = this.createGpuBufferPlane(scaledImage);

    // Aggiorna il materiale del piano con iceRefractionShader

    const depth = this.VIDEO_DEPTH;
    const fov = this.camera.fov;

    // console.log(this.dimension.width);
    let width = this.dimension.width / 2;
    let height = this.dimension.height / 2;

    const aspect = width / height;
    // console.log("Plane ratio  ",aspect);

    const viewportHeightAtDepth =
      2 * depth * Math.tan(THREE.MathUtils.degToRad(0.5 * fov));
    //  console.log(width);
    const viewportWidthAtDepth = viewportHeightAtDepth * aspect;

    this.scene.add(imagePlane);

    if (results.multiFaceGeometry.length > 0) {
      const faceGeometry = results.multiFaceGeometry[0];
      const poseTransformMatrixData = faceGeometry.getPoseTransformMatrix();
      this.faceGroup.matrix.fromArray(
        this.filters.filter(
          Date.now(),
          poseTransformMatrixData.getPackedDataList()
        )
      );
      this.faceGroup.visible = true;
      // console.log(this.faceGroup);
      const mesh = faceGeometry.getMesh();
      let yPos;
      if (this.model3D !== null && this.model3D !== "undefined") {
        //  if(this.count < 20){
        let vertex = mesh.getVertexBufferList();

        switch (this.index) {
          case 10:
            this.model3D.rotation.x = (-45 * Math.PI) / 180;
            this.model3D.position.set(
              vertex[this.index * 5],
              vertex[this.index * 5 + 1] + this.coeffY * 100 + 2.5,
              vertex[this.index * 5 + 2] + this.coeffZ * 10 - 1.5
            );
            // console.log("case 10");
            break;
          case 168:
            this.model3D.rotation.x = this.coeffX * 1 - (1.5 * Math.PI) / 180;
            yPos = this.coeffY * 60 + vertex[this.index * 5 + 1];
            // console.log(yPos)
            // console.log( vertex[this.index*5+1]);
            // console.log(this.coeffY);
            this.model3D.position.set(
              vertex[this.index * 5] + 0.06,
              yPos,
              vertex[this.index * 5 + 2] + this.coeffZ * 10
            );
            this.model3D.rotateX(this.model3D.rotation.x);
            //  console.log("case 168" , yPos);
            break;
          case 6:
            this.model3D.rotation.x = this.coeffX * 1 - Math.PI / 180;
            //  this.model3D.rotation.y = (0.1*Math.PI / 180);
            // console.log("ROTATIOn",this.model3D.rotation.x, this.coeffX * 1, "Z",this.coeffZ, "Y",this.coeffY)

            yPos = this.coeffY * 80 + vertex[this.index * 5 + 1];

            // this.model3D.position.set(vertex[this.index*5] + 0.105 , vertex[this.index*5+1] + (this.coeffY * 5) +0.20, vertex[this.index*5+2]+(this.coeffZ*10))
            this.model3D.position.set(
              vertex[this.index * 5] + 0.06,
              yPos,
              vertex[this.index * 5 + 2] + this.coeffZ * 10
            );
            // console.log("COEF Y:", this.coeffY)
            // console.log("case 6");
            //  this.model3D.rotateX(this.model3D.rotation.x);
            break;
          case 197:
            // this.model3D.rotation.x = (this.coeffX)
            this.model3D.rotation.x = this.coeffX * 1 + (2.5 * Math.PI) / 180;
            yPos = this.coeffY * 1 + vertex[this.index * 5 + 1];

            this.model3D.position.set(
              vertex[this.index * 5] + 0.06,
              vertex[this.index * 5 + 1] + this.coeffY * 100,
              vertex[this.index * 5 + 2] + this.coeffZ * 10
            );
            // console.log("case 197");

            break;
          case 195:
            // this.model3D.rotation.x = (this.coeffX);
            this.model3D.rotation.x = this.coeffX * 1 + (6 * Math.PI) / 180;
            yPos = this.coeffY * 1 + vertex[this.index * 5 + 1];

            this.model3D.position.set(
              vertex[this.index * 5] + 0.06,
              vertex[this.index * 5 + 1] + this.coeffY * 100,
              vertex[this.index * 5 + 2] + this.coeffZ * 10
            );
            // console.log("case 195");

            break;
          case 5:
            // this.model3D.rotation.x = (this.coeffX);
            // console.log((this.coeffX * 1) - 0.195);
            this.model3D.rotation.x = this.coeffX * 1 + (10 * Math.PI) / 180;
            yPos = this.coeffY * 1 + vertex[this.index * 5 + 1];

            this.model3D.position.set(
              vertex[this.index * 5] + 0.06,
              vertex[this.index * 5 + 1] + this.coeffY * 100,
              vertex[this.index * 5 + 2] + this.coeffZ * 10
            );
            // console.log("case 5");

            break;
          default:
            // In caso di vertexIndex non valido, non viene eseguito alcun rotazione
            break;
        }


        if(!this.isPlayed){
          // console.log("FIRST PLAY");
          this.playAnimation();
        }
        // console.log(vertex[vertexIndex*5] , vertex[vertexIndex*5+1], vertex[vertexIndex*5+2])
        this.count = this.count + 1;
        // this.model3D.position.set(vertex[this.index*5] + 0.105 , vertex[this.index*5+1] + (this.coeffY * 10), vertex[this.index*5+2]+(this.coeffZ*10))
        //  }}
      }
    } else {
      this.filters.reset();
      this.faceGroup.visible = false;
    }
    this.renderTarget = new THREE.WebGLRenderTarget(
      this.dimension.width,
      this.dimension.height
    );

    // this.composer.render(this.scene, this.camera);
    if (lastHandResults != null) {
      // console.log("hand land",lastHandResults)
    }

  if (this.mixer) {
        const delta = this.clock.getDelta(); // Assicurati di avere un clock
        this.mixer.update(delta);
    }

    this.renderer.render(this.scene, this.camera);

    this.scene.remove(imagePlane);
  }

  createGpuBufferPlane(gpuBuffer) {
    const depth = this.VIDEO_DEPTH - 1;
    const fov = this.camera.fov;

    // console.log(this.dimension.width);
    let width = this.dimension.width / 2;
    let height = this.dimension.height / 2;

    const aspect = width / height;
    // console.log("Plane ratio  ",aspect);

    const viewportHeightAtDepth =
      2 * depth * Math.tan(THREE.MathUtils.degToRad(0.5 * fov));
    //  console.log(width);
    const viewportWidthAtDepth = viewportHeightAtDepth * aspect;

    const texture = new THREE.CanvasTexture(gpuBuffer);
    texture.minFilter = THREE.LinearMipMapLinearFilter;
    texture.colorSpace = THREE.SRGBColorSpace;

    //   return; // oppure gestisci in altro modo
    // } else {
    //   console.error('Shader or texture not loaded yet');

    const plane = new THREE.Mesh(
      new THREE.PlaneGeometry(1, 1),
      new THREE.MeshBasicMaterial({ map: texture })
    );

    // }

    plane.scale.set(viewportWidthAtDepth, viewportHeightAtDepth, 1);
    plane.position.set(0, 0, -300);
    return plane;
  }

  onCanvasDimsUpdate(results) {
    let videoWidth = results.width;
    let videoHeight = results.height;
    //console.log( results.image.width);
    const aspectRatio = videoWidth / videoHeight;
    let width = this.dimension.width;
    let height = this.dimension.height;
    const windowAspectRatio = width / height;

    while (this.dimension.height < window.innerHeight) {
      this.dimension.width = this.dimension.width * 1.02;
      this.dimension.height = this.dimension.height * 1.02;
      // console.log(width)
    }

    // if(this.countdata == 0){
    //   // console.log("widht height calcolate in DimsUpdate",results.image.width,results.image.height);
    //   // console.log("widht height calcolate in result image ",videoWidth,videoHeight);
    //   // console.log("widht height calcolate in result image ",this.dimension.width,this.dimension.height);
    //   // console.log("aspect camera", this.dimension.width > this.dimension.height ? this.dimension.width / this.dimension.height : this.dimension.height / this.dimension.width)
    //    this.countdata = this.countdata + 1 ;

    // }

    this.renderer.setSize(1109, 1432);
    // this.renderer.setSize(620, 480);

    //this.renderer.setSize(videoWidth,videoHeight)
    // this.renderer.setSize( this.dimension.width /2 ,this.dimension.height /2);
  }
}

function drawBannerAndWatermark(ctx, fotoTemplate, prefix, bannerY, canvas) {
  // Draw the banner
  ctx.fillStyle = fotoTemplate[prefix + "_background_color"];
  ctx.globalAlpha = fotoTemplate[prefix + "_background_transparent"];
  ctx.fillRect(0, bannerY, canvas.width, fotoTemplate[prefix + "_height"]);
  ctx.globalAlpha = 1;

  // Check if watermark position is center
  const isCenterPosition = ["CenterTop", "CenterBottom"].includes(
    fotoTemplate[prefix + "_watermark_position"]
  );

  // Calculate the size of the watermark based on width
  const watermarkWidth = isCenterPosition
    ? fotoTemplate.watermark_center_size
    : fotoTemplate.general_watermark_size;
  const watermarkHeight =
    (watermarkWidth / fotoTemplate[prefix + "_watermark_img"].width) *
    fotoTemplate[prefix + "_watermark_img"].height;

  // Calculate the position of the watermark
  const watermarkX = getWatermarkXPosition(
    fotoTemplate[prefix + "_watermark_position"],
    canvas.width,
    watermarkWidth
  );

  // Center the watermark vertically within the banner
  let watermarkY =
    bannerY + (fotoTemplate[prefix + "_height"] - watermarkHeight) / 2;

  // Add margin if watermark is centered
  if (isCenterPosition) {
    watermarkY += fotoTemplate.margin_center_watermark;
  }
  // Draw the watermark
  ctx.drawImage(
    fotoTemplate[prefix + "_watermark_img"],
    watermarkX,
    watermarkY,
    watermarkWidth,
    watermarkHeight
  );
}

function getWatermarkXPosition(position, canvasWidth, watermarkWidth) {
  switch (position) {
    case "BottomLeft":
    case "TopLeft":
      return 20;
    case "BottomRight":
    case "TopRight":
      return canvasWidth - watermarkWidth - 20;
    case "CenterBottom":
    case "CenterTop":
      return (canvasWidth - watermarkWidth) / 2;
    default:
      return 20;
  }
}
