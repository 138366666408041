import React, { useState } from 'react';
import styles from './SearchBar.module.css';
import iconLeft from '../../asset/1.png'; // Assicurati che il percorso sia corretto
import iconRight from '../../asset/2.png'; // Assicurati che il percorso sia corretto

const SearchBar = ({ onSearch }) => {
  const [query, setQuery] = useState('');

  const handleInputChange = (event) => {
    setQuery(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (onSearch) {
      onSearch(query); // Passa la query al callback
    }
  };

  const clearInput = () => {
    setQuery('');
  };

  return (
    <form onSubmit={handleSubmit} className={styles.searchBarContainer}>
           <img
        src={iconRight}
        alt="Clear Icon"
        className={styles.iconRight}
        onClick={clearInput}
      />
      <input
        type="text"
        value={query}
        onChange={handleInputChange}
        placeholder="Search..."
        className={styles.searchInput}
      />
   
            <img src={iconLeft} alt="Search Icon" className={styles.iconLeft} />

    </form>
  );
};

export default SearchBar;
