// StoreMock.js
export const stores = [
  {
    id: 1,
    name: "Ritual Vision",
    address: "R. Padre Américo 25B, 1600-581 Lisboa, Portogallo",
    latitude: 38.76316,
    longitude: -9.18063,
    description: "ÓPTICA Ritual Vision",
    brand: "Polaroid",
    logo: "ritualvision.png"
  },
  {
    id: 2,
    name: "Alain Afflelou Óptico",
    address: "Centre Commercial Ubbo, Av. Cruzeiro Seixas 5 E 7 Lj 0.041 . Piso 0, 2650-504 Amadora, Portogallo",
    latitude: 38.77753,
    longitude: -9.2186,
    description: "Alain Afflelou Óptico",
    brand: "Polaroid",
    logo: "afflelou.png"
  },
  {
    id: 3,
    name: "Ópticas MultiOpticas Ubbo Tejo Amadora",
    address: "Ubbo Dolce Vita Tejo, Avenida Cruzeiro Seixas 5-7 C. C, Loja 0.069, 2650-504 Amadora, Portogallo",
    latitude: 38.78138,
    longitude: -9.22087,
    description: "Ópticas MultiOpticas Ubbo Tejo Amadora",
    brand: "Polaroid",
    logo: "multiopticas.png"
  },
  {
    id: 4,
    name: "Sunglass Hut Rua Augusta",
    address: "R. Augusta 119, 1100-053 Lisboa, Portogallo",
    latitude: 38.71044,
    longitude: -9.13772,
    description: "Sunglass Hut Rua Augusta",
    brand: "Polaroid",
    logo: "sunglasshut.png"
  },
  {
    id: 5,
    name: "Chiado Eyeglass Factory - Fábrica de Óculos do Chiado",
    address: "R. Garrett Nº33, 1200-309 Lisboa, Portogallo",
    latitude: 38.71081,
    longitude: -9.14038,
    description: "Chiado Eyeglass Factory - Fábrica de Óculos do Chiado",
    brand: "Polaroid",
    logo: "chiado.png"
  },
  {
    id: 6,
    name: "Fora Sunglasses",
    address: "R. da Misericórdia 90, 1200-273 Lisboa, Portogallo",
    latitude: 38.71247,
    longitude: -9.14302,
    description: "Fora Sunglasses",
    brand: "Polaroid",
    logo: "fora.png"
  },
  {
    id: 7,
    name: "Óptica Príncipe Real",
    address: "R. Dom Pedro V Nº 75, 1250-093 Lisboa, Portogallo",
    latitude: 38.71572,
    longitude: -9.14652,
    description: "Óptica Príncipe Real",
    brand: "Polaroid",
    logo: "principereal.png"
  },
  {
    id: 8,
    name: "Óticas OCR Campo de Ourique",
    address: "R. Ferreira Borges 94D, 1350-135 Lisboa, Portogallo",
    latitude: 38.71793,
    longitude: -9.16391,
    description: "Óticas OCR Campo de Ourique",
    brand: "Polaroid",
    logo: "oticasocr.png"
  },
  {
    id: 9,
    name: "Óticas OCR - R. Castilho",
    address: "1250 071, R. Castilho 50 1, 1250-071 Lisboa, Portogallo",
    latitude: 38.72362,
    longitude: -9.15205,
    description: "Óticas OCR - R. Castilho",
    brand: "Polaroid",
    logo: "oticasocr.png"
  },
  {
    id: 10,
    name: "Oculista do Bairro",
    address: "Av. Grão Vasco 45A, 1500-336 Lisboa, Portogallo",
    latitude: 38.74985,
    longitude: -9.20301,
    description: "Oculista do Bairro",
    brand: "Polaroid",
    logo: "odb.png"
  },
  {
    id: 11,
    name: "Opticlasse",
    address: "Estr. de Benfica 759, 1500-089 Lisboa, Portogallo",
    latitude: 38.75210,
    longitude: -9.20574,
    description: "Opticlasse",
    brand: "Polaroid",
    logo: "opticlasse.png"
  },{
    id: 12,
    name: "Olhar de Prata",
    address: "Av. Fontes Pereira de Melo 21, 1050-116 Lisboa, Portogallo",
    latitude: 38.72971,
    longitude: -9.14758,
    description: "Olhar de Prata",
    brand: "Polaroid",
    logo: "olhar.png"
  },
  // Aggiungi ulteriori negozi
  ];
  