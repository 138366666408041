import React from 'react';
import style from './ButtonWithIcon.module.css';

// Utilizza React.forwardRef per avvolgere il componente
const ButtonWithIcon = React.forwardRef(({ language, onSelectLanguage, icon, label , boxButton}, ref) => {
  return (
    // Aggiungi il ref al tag button
    <button ref={ref} className={boxButton ? style["mirror-button-box"] : style["mirror-button"]} onClick={() => onSelectLanguage(language)}>
      {icon && (
      <img src={icon} alt={`${language} flag`} className={style["button-icon"]}/>
      )}
      <span className={style["button-text"]} >{label}</span>
    </button>
  );
});

export default ButtonWithIcon;
